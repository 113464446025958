














import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'
import { FormFieldset } from '../../partials/FormFieldset'

import {
  WebinarsListModule,
  WebinarsListModuleContent,
  WebinarsListModuleContentInput
} from '../WebinarsList.contracts'
import { webinarsListContentFactory } from '../WebinarsList.factory'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<WebinarsListModuleForm>({
  name: 'WebinarsListModuleForm',
  components: { FormFieldset }
})
export class WebinarsListModuleForm extends AbstractModuleForm<WebinarsListModuleContentInput, WebinarsListModule> {
  public initialContent: WebinarsListModuleContent = webinarsListContentFactory()
}

export default WebinarsListModuleForm
