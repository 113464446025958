





























import { Component } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { AbstractModuleForm } from '../../abstract/form'
import { FormFieldset } from '../../partials/FormFieldset'

import {
  FloatingOrderRenewalModule, FloatingOrderRenewalModuleContent,
  FloatingOrderRenewalModuleContentInput
} from '../FloatingOrderRenewal.contracts'
import { floatingOrderRenewalContentFactory } from '../FloatingOrderRenewal.factory'

import { FloatingOrderRenewalPosition } from '../FloatingOrderRenewal.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'FloatingOrderRenewalForm',
  components: { FormFieldset }
})
export class FloatingOrderRenewalForm extends AbstractModuleForm<FloatingOrderRenewalModuleContentInput, FloatingOrderRenewalModule> {
  /**
   * Determines module form initial content
   */
  public initialContent: FloatingOrderRenewalModuleContent = floatingOrderRenewalContentFactory()

  public get positionOptions (): DashmixSelectItem[] {
    return Object.values(FloatingOrderRenewalPosition).map((type) => {
      return {
        label: String(this.$t(`modulesForms.FloatingOrderRenewal.positionOptions.${type}`)),
        value: type
      }
    })
  }

  public onModelUpdate (key: string, value: number | string): void {
    this._content = {
      ...this._content,
      [key]: String(value)
    }
  }
}

export default FloatingOrderRenewalForm
