











































































import { Component, Prop } from 'vue-property-decorator'

import {
  PickerCallback,
  PossibleRelatedPicker,
  Related
} from '../../../contexts'

import { AbstractModuleForm } from '../../abstract/form'
import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm, UnresolvedImage } from '../../partials/ImageForm'
import { Iterator } from '../../partials/Iterator'
import { ProductForm } from '../../partials/ProductForm'
import { MarkdownEditor } from '../../partials/MarkdownEditor'

import { ProductsSetModule, ProductsSetModuleContentInput } from '../ProductsSet.contracts'
import { productsSetContentFactory } from '../ProductsSet.factory'
import { ProductsSetCategoryPriceGroup } from '../ProductsSet.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjanov@movecloser.pl>
 */
@Component({
  name: 'ProductsSetModuleForm',
  components: { FormFieldset, ImageForm, Iterator, ProductForm, MarkdownEditor }
})
export class ProductsSetModuleForm extends AbstractModuleForm<ProductsSetModuleContentInput,
  ProductsSetModule> {
  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  /**
   * Determines related picker.
   */
  public picker = PossibleRelatedPicker
  public productsSetCategoryPriceGroup = ProductsSetCategoryPriceGroup

  /**
   * @override
   */
  protected initialContent = productsSetContentFactory()

  public get thumbnail (): UnresolvedImage | null {
    return {
      image: this._content.thumbnail
    }
  }

  public set thumbnail (thumbnail: UnresolvedImage | null) {
    let contentCopy = { ...this._content }

    contentCopy = {
      ...contentCopy,
      thumbnail: thumbnail && thumbnail.image ? thumbnail.image : null
    }

    this._content = contentCopy
  }

  public addNewObject (): { product: Related; count: number } {
    return {
      product: {} as Related,
      count: 1
    }
  }
}

export default ProductsSetModuleForm
