














































import { DashmixBoxTabItem } from '@movecloser/ui-core'
import { Component, Prop } from 'vue-property-decorator'

import { FormFieldset } from '../../partials/FormFieldset'

import {
  VideoTeaserModule, VideoTeaserModuleContentInput, VideoTeaserInput, VideoTeaserModuleContent
} from '../VideoTeaser.contracts'

import { YouTubePartialForm } from './versions'
import { AbstractModuleForm } from '../../abstract/form'
import { videoContentFactory } from '../VideoTeaser.factory'
import { VideoTabLabel } from './partials/VideoTabLabel.vue'

import { DraggableList } from '../../partials/DraggableList'
import { ModeSwitch } from '../../partials/ModeSwitch'

@Component<VideoTeaserModuleForm>({
  name: 'VideoModuleForm',
  components: { FormFieldset, DraggableList, YouTubePartialForm, ModeSwitch },
})
/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
export class VideoTeaserModuleForm extends AbstractModuleForm<VideoTeaserModuleContentInput, VideoTeaserModule> {
  /**
   * This property is responsible for disabling tabs if EmbedToken in MDE is editing
   *
   * @private
   */
  @Prop({ type: Boolean, required: false, default: false })
  private isEditMode!: boolean

  protected initialContent: VideoTeaserModuleContent = videoContentFactory()

  public activeTab: string | null = '0'

  public get hasItems (): boolean {
    return Array.isArray(this.items) && this.items.length > 0
  }

  public get items (): DashmixBoxTabItem[] {
    const _items = [...Array.from(this._content.items)]

    return [
      ..._items.map((item, index) => {
        return {
          props: {
            item,
            index
          },
          tab: {
            id: `${item.id}`,
            disabled: false,
            label: VideoTabLabel,
            props: {
              tabIndex: index,
              label: `${item.id}`,
              onItemRemove: this.removeItem
            }
          }
        }
      })
    ]
  }

  public set items (items: DashmixBoxTabItem[]) {
    this._content = {
      items: items.map((item: DashmixBoxTabItem) => {
        if (!item.props) {
          return {} as VideoTeaserInput
        }

        return item.props.item as VideoTeaserInput
      })
    }
  }

  public addNewItem (): void {
    this._content.items.push(this.createItem())

    this.activeTab = this._content.items[this._content.items.length - 1].id
  }

  public updateItem (item: VideoTeaserInput, index: number): void {
    if (typeof this._content.items === 'undefined' || !Array.isArray(this._content.items)) {
      throw new Error(
        'VideoTeaserModuleForm.updateItem(): Trying to update item in not existing items list!')
    }

    const contentItemsCopy: VideoTeaserInput[] = [...this._content.items]

    contentItemsCopy[index] = item
    this._content.items = contentItemsCopy
  }

  protected createItem (): VideoTeaserInput {
    return {
      description: '',
      id: 'video ' + Math.floor(Math.random() * 1000).toString(),
      thumbnail: null,
      title: '',
      videoId: ''
    }
  }

  private removeItem (index: number): void {
    if (typeof this._content.items === 'undefined' || !Array.isArray(this._content.items)) {
      throw new Error(
        'VideoTeaserModuleForm.updateItem(): Trying to remove item in not existing items list!')
    }

    this._content.slides.splice(index, 1)

    if (this._content.slides.length > 0) {
      if (index !== 0) {
        this.activeTab = this.items[index - 1].tab.id.toString()
      } else {
        this.activeTab = this.items[index].tab.id.toString()
      }
    } else {
      this.activeTab = null
    }
  }
}

export default VideoTeaserModuleForm
