







import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import { customerSupportContentFactory } from '../CustomerSupport.factory'
import { CustomerSupportModule, CustomerSupportModuleContent, CustomerSupportModuleContentInput }
  from '../CustomerSupport.contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<CustomerSupportModuleForm>({
  name: 'CustomerSupportModuleForm'
})
export class CustomerSupportModuleForm extends AbstractModuleForm<CustomerSupportModuleContentInput,
  CustomerSupportModule> {
  public initialContent: CustomerSupportModuleContent = customerSupportContentFactory()
}

export default CustomerSupportModuleForm
