




































import { Component } from 'vue-property-decorator'
import { DashmixBoxTabItem } from '@movecloser/ui-core'

import { AbstractModuleForm } from '../../abstract/form'
import { FormFieldset } from '../../partials/FormFieldset'
import { ItemTab } from './partials/ItemTab.vue'

import { imageListContentFactory } from '../AttachmentsList.factory'
import {
  AttachmentsListModule,
  AttachmentsListModuleContent,
  AttachmentsListModuleContentInput, UnresolvedListItem
} from '../AttachmentsList.contracts'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<AttachmentsListModuleForm>({
  name: 'AttachmentsListModuleForm',
  components: { FormFieldset, ItemTab }
})
export class AttachmentsListModuleForm extends AbstractModuleForm<AttachmentsListModuleContentInput, AttachmentsListModule> {
  /**
   * Initial active tab
   */
  public activeTab: string | null = '0'

  public initialContent: AttachmentsListModuleContent = imageListContentFactory()

  public get hasItems (): boolean {
    return typeof this._content.items !== 'undefined' &&
      Array.isArray(this._content.items) && this._content.items.length > 0
  }

  public get items (): DashmixBoxTabItem[] {
    return [
      ...this._content.items.map((item, index) => {
        return {
          props: {
            item,
            index
          },
          tab: {
            id: `${item.id}`,
            disabled: false,
            label: item.title ? item.title : `Element ${index + 1}`,
            props: {
              tabIndex: index,
              label: `${item.id}`,
              onSlideRemove: this.removeItem
            }
          }
        }
      })
    ]
  }

  /**
   * Create empty item
   */
  public createItem (): void {
    this._content.items.push({
      id: 'item ' + Math.floor(Math.random() * 1000).toString(),
      file: null,
      title: null
    })

    this.activeTab = this._content.items[this._content.items.length - 1].id
  }

  /**
   * Replaces current item with updated one
   * @param item
   * @param index
   */
  public updateItem (item: UnresolvedListItem, index: number): void {
    if (typeof this._content.items === 'undefined' || !Array.isArray(this._content.items)) {
      throw new Error(
        'AttachmentsListModuleForm.updateSlide(): Try to update item in not existing item list!')
    }

    const contentItemCopy: UnresolvedListItem[] = [...this._content.items]

    contentItemCopy[index] = item
    this._content.items = contentItemCopy
  }

  /**
   * Remove item with given index
   * @param index
   * @private
   */
  private removeItem (index: number): void {
    if (typeof this._content.items === 'undefined' || !Array.isArray(this._content.items)) {
      throw new Error(
        'AttachmentsListModuleForm.removeItem(): Try to remove item from not existing items list!')
    }

    this._content.items.splice(index, 1)

    if (this._content.items.length > 0) {
      if (index !== 0) {
        this.activeTab = this.items[index - 1].tab.id.toString()
      } else {
        this.activeTab = this.items[index].tab.id.toString()
      }
    } else {
      this.activeTab = null
    }
  }
}

export default AttachmentsListModuleForm
