
























































import { Component, Prop, PropSync } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { Identifier, Related, SetDescription, SetType } from '../../../contexts'
import { fromRange } from '../../../support/array-utils'

import { RelatedPartial } from '../RelatedPartial'

/**
 * Component capable to handle different types of Related sets
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<SetForm>({ name: 'SetForm' })
export class SetForm extends RelatedPartial<SetDescription> {
  @PropSync('count', { type: Number, required: false, default: undefined })
  public _count?: number

  @Prop({ type: Number, required: false, default: 1 })
  private readonly minSelectable!: number

  @Prop({ type: Number, required: false, default: 15 })
  private readonly maxSelectable!: number

  @Prop({ type: String, required: true })
  public readonly picker!: string

  @Prop({ type: Boolean, required: false, default: false })
  private readonly selectCount!: boolean

  @PropSync('set', { type: Object, required: false, default: null })
  public _set!: null

  @Prop({ type: [String, Number], required: false })
  public readonly siteId?: Identifier

  /**
   * Determines whether count of displayed offers is defined by user.
   */
  public get canSelectCount (): boolean {
    return this._count !== undefined
  }

  /**
   * Sets newValue for `canSelectCount`
   */
  public set canSelectCount (value: boolean) {
    if (!value) {
      this._count = undefined
      return
    }

    this._count = this.minSelectable
  }

  /**
   * Checks whether relatedSet is not present
   */
  public get isEmpty (): boolean {
    return this._set === null
  }

  /**
   * @inheritDoc
   */
  public get isRelatedSet (): boolean {
    return this._set !== null
  }

  public get isRelated (): boolean {
    if (!this.isRelatedSet) {
      return false
    }

    return Object.prototype.hasOwnProperty.call(this._set, 'type') &&
      Object.prototype.hasOwnProperty.call(this._set, 'value')
  }

  public get itemsCountOptions (): DashmixSelectItem[] {
    return [...fromRange(this.minSelectable, this.maxSelectable).map(
      (index: number) => ({
        label: String(index),
        value: index
      })
    )
    ]
  }

  /**
   * Assigns selected related to `_relatedSet`
   */
  public addRelatedTarget (): void {
    if (this.isEmpty) {
      return
    }

    this.pickRelated(this.picker, this.setRelated, this.getRelatedSource(), { siteId: this.siteId })
  }

  /**
   * Resets related set
   */
  public clearSet (): void {
    this._set = null
    this.$emit('clear')
  }

  public selectRelatedSet (): void {
    this.pickRelated(this.picker, this.setRelated, this.getRelatedSource(), { siteId: this.siteId })
  }

  /**
   * @inheritDoc
   * @protected
   */
  protected getRelatedSource (): Related {
    // Note! This ignore is caused by nature of computed value in Vue.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return this._set
  }

  /**
   * Sets chosen set to `_relatedSet`
   * @param selected
   * @protected
   */
  protected setRelated (selected: SetType): void {
    // Note! This ignore is caused by nature of computed value in Vue.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this._set = selected
    this.$nextTick(() => this.describeRelated(this.siteId))
  }
}

export default SetForm
