






















































































import { Component } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { AbstractModuleForm } from '../../abstract/form'

import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm } from '../../partials/ImageForm'
import { MarkdownEditor } from '../../partials/MarkdownEditor'
import { LinkForm } from '../../partials/LinkForm'

import { InfoCardModule, InfoCardModuleContentInput, Separators as SeparatorsMap } from '../InfoCard.contracts'
import { infoCardContentFactory } from '../InfoCard.factory'

@Component<InfoCardModuleForm>({
  name: 'InfoBoxModuleForm',
  components: { ImageForm, FormFieldset, MarkdownEditor, LinkForm }
})
export class InfoCardModuleForm extends AbstractModuleForm<InfoCardModuleContentInput, InfoCardModule> {
  protected initialContent = infoCardContentFactory()

  public get hasCTA (): boolean {
    return typeof this._content.cta !== 'undefined'
  }

  public set hasCTA (value: boolean) {
    const contentCopy = { ...this._content }
    if (!value) {
      contentCopy.cta = undefined
    } else {
      contentCopy.cta = {
        action: null
      }
    }

    this._content = contentCopy
  }

  public get separatorsOptions (): DashmixSelectItem[] {
    return Object.entries(SeparatorsMap).map(([name, value]) => {
      return {
        label: String(this.$t(`modulesForms.InfoCard.separators.types.options.${name}`)),
        value
      }
    }).filter(option => typeof option.value === 'string')
  }
}

export default InfoCardModuleForm
