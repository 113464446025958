

























































































import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName, IFile } from '@movecloser/ui-core'
import { AnyObject, ConjunctionOperator } from '@movecloser/front-core'

import { IRelatedService, PickerCallback } from '../../../../../contexts'

import { MarkdownEditor } from '../../../../partials/MarkdownEditor'
import { ImageForm } from '../../../../partials/ImageForm'

import { YOUTUBE_REGEX } from '../../../Video.config'

import { EmbedVersionAbstractForm } from '../version.abstract.form'
import { ImageProps } from '../../../../../dsl/atoms/Image'

/**
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({
  name: 'YouTubePartialForm',
  components: { ImageForm, MarkdownEditor }
})
export class YouTubePartialForm extends EmbedVersionAbstractForm {
  @Prop({ type: String, required: false, default: '' })
  public imgTitle!: string

  @Prop({ type: Function, required: true })
  public onAutoplayToggle!: () => void

  @Prop({ type: Function, required: true })
  public onSelection!: () => void

  @Prop({ type: Function, required: true })
  public onRemove!: () => void

  @Prop({ type: Function, required: true })
  public onVideoIdChange!: (id: string) => void

  @Prop({ type: Object, required: false, default: null })
  public thumbnail!: IFile | null

  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  public readonly DashmixIconName = DashmixIconName

  public readonly regex = YOUTUBE_REGEX

  public useIframe: string = 'iframe'

  public switchOptions = [
    {
      label: 'Wklej iframe',
      value: 'iframe'
    },
    {
      label: 'Podaj Id',
      value: 'id'
    }
  ]

  public get youTubeCover (): ImageProps {
    return {
      src: `https://img.youtube.com/vi/${this._content.videoId}/0.jpg`,
      alt: 'YouTube cover video'
    }
  }

  public get url (): string {
    if (!this._content || !this._content.videoId) {
      return ''
    } else {
      return `https://www.youtube.com/watch?v=${this._content.videoId}`
    }
  }

  public set url (url: string) {
    if (!url) {
      return
    }

    const youtubeMatch = url.match(this.regex)

    if (youtubeMatch) {
      this.onVideoIdChange(youtubeMatch[7])
    }
  }

  public get convertedThumbnail (): AnyObject {
    return { image: this._content.thumbnail }
  }
}

export default YouTubePartialForm
