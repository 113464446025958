















































import { Component, Watch } from 'vue-property-decorator'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { AbstractModuleForm } from '../../abstract/form'
import { Inject } from '../../../support'

import { ClassForm } from '../../partials/ClassForm'
import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm, UnresolvedImage } from '../../partials/ImageForm'
import { LinkForm } from '../../partials/LinkForm'

import { profileNavContentFactory } from '../ProfileNav.factory'
import {
  ProfileNavModule,
  ProfileNavModuleContent,
  ProfileNavModuleContentInput
} from '../ProfileNav.contracts'

import { ProfileNavItemLabel } from './partials/ProfileNavItemLabel.vue'
import { NavigationForm } from '../../partials/NavigationForm'

/**
 * Form component for the `Profile Nav` module.
 *
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */

@Component<ProfileNavModuleForm>({
  name: 'ProfileNavModuleForm',
  components: { ClassForm, FormFieldset, ImageForm, LinkForm, NavigationForm, ProfileNavItemLabel },
  mounted (): void {
    this.hasBackground = !!this._content.background
  }
})
export class ProfileNavModuleForm extends AbstractModuleForm<ProfileNavModuleContentInput, ProfileNavModule> {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public initialContent: ProfileNavModuleContent = profileNavContentFactory()

  public hasBackground: boolean = false

  public get background (): UnresolvedImage | null {
    if (!this._content.background) {
      return null
    }

    return {
      image: this._content.background
    }
  }

  public set background (image: UnresolvedImage | null) {
    const contentCopy = { ...this._content }

    if (!image || !image.image) {
      delete contentCopy.background
    } else {
      contentCopy.background = image.image
    }

    this._content = contentCopy
  }

  public get hasCTA (): boolean {
    return typeof this._content.cta !== 'undefined'
  }

  public set hasCTA (value: boolean) {
    this._content = {
      ...this._content,
      cta: value ? { label: '' } : undefined
    }
  }

  @Watch('hasBackground')
  public onSetHasBackground (value: boolean) {
    if (!value) {
      this._content = {
        ...this._content,
        background: null
      }
    }
  }
}

export default ProfileNavModuleForm
