







































































































import { Component } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { AbstractModuleForm } from '../../abstract/form'

import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm } from '../../partials/ImageForm'
import { MarkdownEditor } from '../../partials/MarkdownEditor'
import { LinkForm } from '../../partials/LinkForm'

import { CTABoxModule, CTABoxModuleContentInput } from '../CTABox.contracts'
import { ctaBoxContentFactory } from '../CTABox.factory'
import { YOUTUBE_REGEX } from '../../VideoTeaser/VideoTeaser.config'
import { CTABoxModuleVersion } from '../CTABox.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CTABoxModuleForm>({
  name: 'CTABoxModuleForm',
  components: { ImageForm, FormFieldset, MarkdownEditor, LinkForm }
})
export class CTABoxModuleForm extends AbstractModuleForm<CTABoxModuleContentInput, CTABoxModule> {
  protected initialContent = ctaBoxContentFactory()

  public CTABoxModuleVersion = CTABoxModuleVersion

  public get directionOptions (): DashmixSelectItem[] {
    return ['right', 'left'].map((direction) => {
      return {
        label: String(this.$t(`modulesForms.CTABox.direction.options.${direction}`)),
        value: direction
      }
    })
  }

  public get useFile (): boolean {
    return typeof this._content.cta?.image !== 'undefined'
  }

  public set useFile (value: boolean) {
    const contentCopy = { ...this._content }
    if (!value) {
      // contentCopy.cta!.image = undefined
      delete contentCopy.cta!.image
      delete contentCopy.cta!.fileName
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      contentCopy.cta.image = {}
    }

    this._content = contentCopy
  }

  public get hasCTA (): boolean {
    return typeof this._content.cta !== 'undefined'
  }

  public set hasCTA (value: boolean) {
    const contentCopy = { ...this._content }
    if (!value) {
      contentCopy.cta = undefined
    } else {
      contentCopy.cta = {
        action: null,
        action2: null
      }
    }

    this._content = contentCopy
  }

  /**
   * Handling Video
   */
  public readonly regex = YOUTUBE_REGEX

  public get url (): string {
    if (!this._content || !this._content.videoId) {
      return ''
    } else {
      return `https://www.youtube.com/watch?v=${this._content.videoId}`
    }
  }

  public set url (url: string) {
    if (!url) {
      return
    }

    const youtubeMatch = url.match(this.regex)

    if (youtubeMatch) {
      this.onVideoIdChange(youtubeMatch[7])
    }
  }

  public onVideoIdChange (id: string) {
    this._content = {
      ...this._content,
      videoId: id
    }
  }

  /**
   * END Handling Video
   */
}

export default CTABoxModuleForm
