























import { Component } from 'vue-property-decorator'

import { MarkdownEditor } from '../../partials/MarkdownEditor'
import { AbstractModuleForm } from '../../abstract/form'
import { FormFieldset } from '../../partials/FormFieldset'
import { HeadingForm } from '../../partials/HeadingForm'
import { NewsletterModule, NewsletterModuleContent, NewsletterModuleContentData } from '../Newsletter.contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component({
  name: 'NewsletterModuleForm',
  components: { FormFieldset, HeadingForm, MarkdownEditor }
})
export class NewsletterModuleForm extends AbstractModuleForm<NewsletterModuleContentData, NewsletterModule> {
  public initialContent: NewsletterModuleContent = {
  }
}

export default NewsletterModuleForm
