









import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import {
  ProductsUpsellSourceConfigurationOptions,
  ProductsUpsellSourceOptions,
  ProductUpsellSourceMode
} from '../ProductsUpsell.contracts'
import ComponentOptions from '../../partials/ComponentOptions/ComponentOptions.mixin.vue'

/**
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component<ProductsUpsellSourceForm>({
  name: 'ProductsUpsellSourceForm'
})
export class ProductsUpsellSourceForm extends Mixins(ComponentOptions) {
  @Prop({ type: Object, required: false, default: null })
  public options!: ProductsUpsellSourceOptions

  /**
   * Determines component config.
   */
  public config: ProductsUpsellSourceConfigurationOptions = this.getComponentOptions({
    modeOptions: [
      ProductUpsellSourceMode.Filters,
      ProductUpsellSourceMode.Brand,
      ProductUpsellSourceMode.BrandAndCategory,
      ProductUpsellSourceMode.Category,
      ProductUpsellSourceMode.Line
    ]
  })

  public mode: string | null = this.options?.mode ?? null

  public sku: boolean | null = this.options?.sku ?? null

  public availableModes = this.config.modeOptions.map(modeOption => {
    return {
      label: this.$t(`modulesForms.ProductsUpsell.options.${modeOption}`).toString(),
      value: modeOption
    }
  })

  @Watch('mode')
  public onModeUpdate (value: string) {
    if (value === ProductUpsellSourceMode.Filters) {
      this.$emit('update:options', { ...this.options, mode: value, sku: false })
    } else {
      this.$emit('update:options', { ...this.options, mode: value, sku: true })
    }
  }
}

export default ProductsUpsellSourceForm
