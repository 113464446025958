





















































import { Component, Prop, Watch } from 'vue-property-decorator'

import { PickerCallback, Related } from '../../../contexts'

import { AbstractModuleForm } from '../../abstract/form'
import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm } from '../../partials/ImageForm'
import { ProductForm } from '../../partials/ProductForm'

import {
  UsageCalculatorModule,
  UsageCalculatorModuleContentInput, UsageCalculatorProduct
} from '../UsageCalculator.contracts'
import { usageCalculatorContentFactory } from '../UsageCalculator.factory'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<UsageCalculatorModuleForm>({
  name: 'UsageCalculatorModuleForm',
  components: {
    ImageForm,
    FormFieldset,
    ProductForm
  },
  mounted () {
    this.products = this.productKeys.reduce((acc, key) => {
      const product = this._content?.products ? this._content.products.find(p => p.key === key)?.related : null

      return {
        ...acc,
        [key]: product ?? null
      }
    }, {} as Record<string, Related | null>)

    // if (this._content.redirect) {
    //   this.useRedirect = true
    // }
  }
})
export class UsageCalculatorModuleForm extends AbstractModuleForm<UsageCalculatorModuleContentInput, UsageCalculatorModule> {
  protected initialContent = usageCalculatorContentFactory()

  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  public readonly productKeys = Object.keys(UsageCalculatorProduct)
  public products: Record<string, Related | null> | null = null
  // public useRedirect = false

  @Watch('products', { deep: true })
  private updateProducts () {
    if (!this.products) {
      return
    }

    this._content.products = this.productKeys.map(key => ({
      key: key as UsageCalculatorProduct,
      related: this.products ? this.products[key] : null
    }))
  }
}

export default UsageCalculatorModuleForm
