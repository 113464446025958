















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import * as draggable from 'vuedraggable'
import { AnyObject } from '@movecloser/front-core'

@Component<DraggableList>({
  name: 'DraggableList',
  components: { draggable: draggable.default }
})
export class DraggableList extends Vue {
  @Prop({ type: String, required: false, default: 'handle' })
  public readonly handle!: string

  @PropSync('model', { type: Array, required: true })
  public _model!: AnyObject[]

  @Prop({ type: String, required: false, default: '' })
  public readonly wrapperClass!: string
}
export default DraggableList
