



























































import { Component, Prop } from 'vue-property-decorator'
import { IFile, ImageType } from '@movecloser/ui-core'
import { AnyObject } from '@movecloser/front-core'

import { IRelatedService, PickerCallback } from '../../../../../contexts'

import { MarkdownEditor } from '../../../../partials/MarkdownEditor'
import { ImageForm } from '../../../../partials/ImageForm'

import { YOUTUBE_REGEX } from '../../../VideoTeaser.config'

import { EmbedVersionAbstractForm } from '../version.abstract.form'
import { VideoVersion } from '../../../VideoTeaser.contracts'

/**
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<YouTubePartialForm>({
  name: 'YouTubePartialForm',
  components: { ImageForm, MarkdownEditor },
  mounted (): void {
    this.$nextTick(() => {
      switch (this._version) {
        case VideoVersion.YouTube:
          if (typeof this._item.autoplay === 'undefined') {
            this.toggleAutoplay(true)
          }
          this.findImageRelated()
          break
      }
    })
  }
})
export class YouTubePartialForm extends EmbedVersionAbstractForm {
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  public _version = VideoVersion.YouTube

  public readonly regex = YOUTUBE_REGEX

  public imgTitle: string = ''

  public thumbnail: IFile | null = null

  public get url (): string {
    if (!this._item || !this._item.videoId) {
      return ''
    } else {
      return `https://www.youtube.com/watch?v=${this._item.videoId}`
    }
  }

  public set url (url: string) {
    if (!url) {
      return
    }

    const youtubeMatch = url.match(this.regex)

    if (youtubeMatch) {
      this.onVideoIdChange(youtubeMatch[7])
    }
  }

  public onVideoIdChange (id: string) {
    this._item = {
      ...this._item,
      videoId: id
    }
  }

  public get convertedThumbnail (): AnyObject {
    return { image: this._item.thumbnail }
  }

  public isPromise (obj: any) {
    return obj instanceof Promise
  }

  public toggleAutoplay (auto: boolean): void {
    const item = this._item
    item.autoplay = auto
    this._item = item
  }

  protected getImageFromRelated (related: AnyObject) {
    if (Object.prototype.hasOwnProperty.call(related, 'alt')) {
      this.imgTitle = related.title
    }

    if (Object.prototype.hasOwnProperty.call(related, 'url')) {
      this.thumbnail = {
        type: ImageType.JPG,
        thumbnail: related.url
      }

      if (Object.prototype.hasOwnProperty.call(related, 'mime')) {
        this.thumbnail.type = related.mime
      }
    }
  }

  protected findImageRelated () {
    if (!this._item.thumbnail || typeof this._item.thumbnail.value === 'undefined' ||
      typeof this._item.thumbnail.type === 'undefined') {
      return
    }
    const related = this.relatedService.resolve(this._item.thumbnail, {})

    if (!this.isPromise(related)) {
      this.getImageFromRelated(related)
      return
    }

    related.then((related: AnyObject) => {
      this.getImageFromRelated(related)
    }).catch(error => {
      console.debug(error)
    })
  }

  protected setThumbnailSource (payload: AnyObject): void {
    if (!payload || !payload.hasOwnProperty('image')) {
      return
    }

    this._item.thumbnail = payload.image

    this.$nextTick(() => {
      this.findImageRelated()
    })
  }
}

export default YouTubePartialForm
