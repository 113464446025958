







import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import { benefitsBoxContentFactory } from '../BenefitsBox.factory'
import { BenefitsBoxModule, BenefitsBoxModuleContent, BenefitsBoxModuleContentInput } from '../BenefitsBox.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<BenefitsBoxModuleForm>({
  name: 'BenefitsBoxModuleForm'
})
export class BenefitsBoxModuleForm extends AbstractModuleForm<BenefitsBoxModuleContentInput, BenefitsBoxModule> {
  public initialContent: BenefitsBoxModuleContent = benefitsBoxContentFactory()
}

export default BenefitsBoxModuleForm
