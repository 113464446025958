



































import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import { ClassForm } from '../../partials/ClassForm'
import { ColorSchemaSelector } from '../../partials/ColorSchemaSelector'
import { HAlignmentSelector } from '../../partials/AlignmentSelector'
import { MarkdownEditor } from '../../partials/MarkdownEditor'

import { textContentFactory } from '../Text.factory'
import {
  TextModule,
  TextModuleContent,
  TextModuleContentInput,
  TextOptions
} from '../Text.contracts'
import { AllowedColor } from '../../../contexts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Olga Milczek <olga.milczek@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<TextModuleForm>({
  name: 'TextModuleForm',
  components: {
    ClassForm,
    ColorSchemaSelector,
    HAlignmentSelector,
    MarkdownEditor
  },
  created () {
    if (this._content.extendedContent && this._content.extendedContent.length > 0) {
      this.useExtendedText = true
    }
  }
})
export class TextModuleForm extends AbstractModuleForm<TextModuleContentInput, TextModule> {
  public initialContent: TextModuleContent = textContentFactory()

  /**
   * Property determine wherever extendedText is used or not.
   */
  public useExtendedText: boolean = false

  /**
   * Property to store extended text value.
   */
  public storedExtendedText: string = ''

  /**
   * Determines component config.
   */
  public config: TextOptions = this.getComponentOptions(
    { allowedColors: AllowedColor }
  )

  /**
   * Method to change value of `useExtendedText`.
   */
  public onUseExtendedTextChange (value: boolean): void {
    this.useExtendedText = value

    this.changeExtendedTextValue(value)
  }

  /**
   * Method to delete and add to store or add from store extendedContent value
   * depending on the value of `useExtendedText`.
   */
  private changeExtendedTextValue (useExtendedText: boolean): void {
    const contentCopy = { ...this._content }

    if (!useExtendedText) {
      this.storedExtendedText = contentCopy.extendedContent ?? ''
      delete contentCopy.extendedContent
    } else {
      contentCopy.extendedContent = this.storedExtendedText
    }

    this._content = contentCopy
  }
}

export default TextModuleForm
