






















































































































import { Component, Prop, Watch } from 'vue-property-decorator'

import {
  DescriptionOfBaseFilter,
  PickerCallback,
  PossibleRelatedPicker
} from '../../../contexts'
import { FormCheckbox } from '../../../backoffice/shared/components'

import { AbstractModuleForm } from '../../abstract/form'
import { FilterForm } from '../../partials/FilterForm'
import { FormFieldset } from '../../partials/FormFieldset'
import { HeadingForm } from '../../partials/HeadingForm'
import { Iterator } from '../../partials/Iterator'
import { SetForm } from '../../partials/SetForm'

import { productsListContentFactory } from '../ProductsList.factory'
import {
  ProductsListModule,
  ProductsListModuleContent,
  ProductsListModuleContentInput,
  ProductsListSourceOptions
} from '../ProductsList.contracts'
import { SelectItem } from '../../../dsl/atoms/Select'
import { NavigationForm } from '../../partials/NavigationForm'

/**
 * Form component for the `ProductsList` module.
 *
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ProductsListModuleForm>({
  name: 'ProductsListModuleForm',
  components: { FilterForm, FormCheckbox, FormFieldset, HeadingForm, Iterator, NavigationForm, SetForm }
})
export class ProductsListModuleForm extends AbstractModuleForm<ProductsListModuleContentInput, ProductsListModule, ProductsListSourceOptions> {
  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: true })
  public readonly pickRelated!: PickerCallback

  /**
   * Determines products list initial content.
   */
  public initialContent: ProductsListModuleContent = productsListContentFactory()

  public options: Map<string, SelectItem[]> = new Map()

  /**
   * Determines related picker.
   */
  public picker = PossibleRelatedPicker

  public get isFilters (): boolean {
    return this.isDynamic && this.sourceOptions?.mode === 'filters'
  }

  public get hasSimplePagination (): boolean {
    return !!this._content.hasSimplePagination
  }

  public get hasCart (): boolean {
    return this._content.hasCart !== false
  }

  public set hasCart (value: boolean) {
    this._content.hasCart = value
  }

  public addFilter () {
    return null
  }

  public addPredefinedFilter () {
    return { filter: null, value: [] }
  }

  public updateOptions (filterValue: string, described: DescriptionOfBaseFilter) {
    const options = Object.entries(described.options).map(([value, label]) => ({ label, value }))

    this.options.set(filterValue, options)
    this.options = new Map(this.options)
  }

  @Watch('hasSimplePagination')
  protected onContentChange (): void {
    if (!this._content.hasSimplePagination) {
      this._content.initialRowAmount = 0
    }

    this._content = Object.entries(this._content).reduce((acc, [key, value]) => {
      if (!value) {
        return acc
      }

      return { ...acc, [key]: value }
    }, {}) as ProductsListModuleContentInput
  }
}

export default ProductsListModuleForm
