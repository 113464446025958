// Copyright © 2022 Move Closer

import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { VideoTeaserInput, VideoVersion } from '../../VideoTeaser.contracts'
import { IRelatedService, PickerCallback } from '../../../../contexts'

import { DashmixIconName } from '@movecloser/ui-core'
/**
 * Abstract video form
 *
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({
  name: 'EmbedVersionAbstractForm'
})
export class EmbedVersionAbstractForm extends Vue {
  /**
   * Content of `EmbedModule`
   */
  @PropSync('item', { type: Object, required: true })
  public _item!: VideoTeaserInput

  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  /**
   * Override this expression with appropriate version's expression
   * @protected
   */
  protected readonly regex: RegExp = /\$/

  public readonly DashmixIconName = DashmixIconName

  public readonly _version!: VideoVersion
}

export default EmbedVersionAbstractForm
