



































import { Component } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import {
  ProductHeaderModule,
  ProductHeaderModuleContent,
  ProductHeaderModuleContentInput
} from '../ProductHeader.contracts'
import { productHeaderContentFactory } from '../ProductHeader.factory'
import { ProductForm } from '../../partials/ProductForm'
import { FormFieldset } from '../../partials/FormFieldset'
import { LinkForm, UnresolvedLink } from '../../partials/LinkForm'
import { Iterator } from '../../partials/Iterator'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ProductHeaderModuleForm>({
  name: 'ProductHeaderModuleForm',
  components: {
    FormFieldset,
    Iterator,
    LinkForm,
    ProductForm
  }
})
export class ProductHeaderModuleForm extends AbstractModuleForm<ProductHeaderModuleContentInput, ProductHeaderModule> {
  public initialContent: ProductHeaderModuleContent = productHeaderContentFactory()

  public get hasButtons (): boolean {
    return !!this._content.buttons
  }

  public set hasButtons (value: boolean) {
    if (value && !this._content.buttons) {
      this._content.buttons = []
    } else if (!value) {
      this._content.buttons = null
    }
  }

  public addNewButton (): UnresolvedLink | null {
    return null
  }
}

export default ProductHeaderModuleForm
