





























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { ColorSchemaSelector } from '../../../partials/ColorSchemaSelector'
import { FormFieldset } from '../../../partials/FormFieldset'
import { ImageForm, UnresolvedImage } from '../../../partials/ImageForm'

import { UnresolvedListItem } from '../../AttachmentsList.contracts'
import { IRelatedService, PickerCallback } from '../../../../contexts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ItemTab>({
  name: 'ItemTab',
  components: { ColorSchemaSelector, FormFieldset, ImageForm }
})
export class ItemTab extends Vue {
  @PropSync('item', { type: Object, required: true })
  public _item!: UnresolvedListItem

  /**
   * Callback used by form to pick relate.
   */
  @Prop({ type: Function, required: false })
  public readonly pickRelated!: PickerCallback

  /**
   * Service capable of resolving the actual data of the related objects.
   */
  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  public get file (): UnresolvedImage | null {
    if (!Object.prototype.hasOwnProperty.call(this._item, 'file')) {
      return null
    }

    if (this._item.file === null) {
      return null
    }

    return {
      image: this._item.file
    }
  }

  public set file (image: UnresolvedImage | null) {
    if (!image) {
      const itemCopy = { ...this._item }
      itemCopy.file = null
      this._item = itemCopy
      return
    }

    this._item = {
      ...this._item,
      file: image.image
    }
  }
}

export default ItemTab
