














import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<TagWrapper>({
  name: 'TagWrapper',
  created () {
    const steps = [...this.steps]

    this.thisStep = steps.shift() ?? 'span'
    this.restSteps = steps
  }
})
export class TagWrapper extends Vue {
  public thisStep: string = ''
  public restSteps: string[] = []

  @Prop({ type: Array, required: true })
  public readonly steps!: string[]

  public get isWrapper (): boolean {
    return this.restSteps.length > 0
  }
}

export default TagWrapper
