













import { Component, Prop, Vue } from 'vue-property-decorator'
import { ModuleEditMode, moduleEditModeIcons } from '../../../contexts'

/**
 * Change edit mode of module form component.
 *
 * @author Kuba Fogel <kuba.fogel@movecloser.pl>
 */
@Component<ModeSwitch>({ name: 'ModeSwitch' })
export class ModeSwitch extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public readonly enabled!: boolean

  @Prop({ type: String, required: true })
  public readonly mode!: ModuleEditMode

  public get modeIcon (): string {
    return moduleEditModeIcons[this.mode]
  }

  public onSwitchMode () {
    this.$emit('switchMode')
  }
}

export default ModeSwitch
