













































































































































































import { Component, Watch } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'

import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm, UnresolvedImage } from '../../partials/ImageForm'
import { LinkForm } from '../../partials/LinkForm'
import { NavigationForm } from '../../partials/NavigationForm'

import { FooterModule, FooterModuleContentInput } from '../Footer.contracts'
import { footerContentFactory } from '../Footer.factory'
import { FooterBadgeType, FooterModuleVersion } from '../Footer.config'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<FooterModuleForm>({
  name: 'FooterModuleForm',
  components: {
    FormFieldset,
    NavigationForm,
    ImageForm,
    LinkForm
  },
  mounted () {
    if (this._content.badgeType) {
      this.badgeOption = this._content.badgeType
    }

    this.image = {
      ...this._content.brandImageDimensions,
      image: this._content.brandImage ?? null
    }
  }
})
export class FooterModuleForm extends AbstractModuleForm<FooterModuleContentInput, FooterModule> {
  protected initialContent = footerContentFactory()
  public badgeOption: string | null = null
  public badgeType = FooterBadgeType
  public image: UnresolvedImage | null = null

  public get defaultVersion (): boolean {
    return this._version === FooterModuleVersion.Default
  }

  public get simpleVersion (): boolean {
    return this._version === FooterModuleVersion.Simple
  }

  @Watch('image', { deep: true })
  public updateImage (image: UnresolvedImage | null) {
    if (!image) {
      const contentCopy = { ...this._content }
      delete contentCopy.brandImage
      delete contentCopy.brandImageDimensions
      this._content = contentCopy
      return
    }
    this._content = {
      ...this._content,
      brandImage: image.image,
      brandImageDimensions: { maxWidth: image.maxWidth, maxHeight: image.maxHeight }
    }
  }

  public get footerBadgeOptions (): Array<Record<string, string>> {
    const options: Record<string, string>[] = []
    for (const option of Object.values(FooterBadgeType)) {
      options.push({
        label: this.$t(`modulesForms.Footer.badge.options.${option}`).toString(),
        value: option as string
      })
    }
    return options
  }

  @Watch('badgeOption')
  public onBadgeShowChange () {
    if (this.badgeOption === FooterBadgeType.Auto) {
      this._content = {
        ...this._content,
        badge: null,
        badgeType: this.badgeOption
      }
    } else {
      if (this._content.badge) {
        this._content = {
          ...this._content,
          badgeType: this.badgeOption
        }
      } else {
        this._content = {
          ...this._content,
          badge: {
            amount: null,
            desc: null,
            icon: null,
            rate: null,
            sectionTitle: '',
            link: undefined
          },
          badgeType: this.badgeOption
        }
      }
    }
  }
}

export default FooterModuleForm
