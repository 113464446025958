




















































import { Component, Watch } from 'vue-property-decorator'
import { DashmixBoxTabItem } from '@movecloser/ui-core'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import { AbstractModuleForm } from '../../abstract/form'
import { Inject, REFRESH_MDE_KEY } from '../../../support'

import { DraggableList } from '../../partials/DraggableList'
import { FormFieldset } from '../../partials/FormFieldset'
import { MarkdownEditor } from '../../partials/MarkdownEditor'
import { ModeSwitch } from '../../partials/ModeSwitch'
import { HeadingForm } from '../../partials/HeadingForm'
import { ImageForm } from '../../partials/ImageForm'

import { heroContentFactory } from '../Hero.factory'
import {
  HeroModule,
  HeroModuleContent,
  HeroModuleContentInput,
  SlidesInput
} from '../Hero.contracts'

import { SlideTab, SlideTabLabel } from './partials'

/**
 * Form component for the `Hero` module.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl> (original)
 */
@Component<HeroModuleForm>({
  name: 'HeroModuleForm',
  components: {
    FormFieldset,
    MarkdownEditor,
    HeadingForm,
    ImageForm,
    SlideTab,
    DraggableList,
    ModeSwitch
  }
})
export class HeroModuleForm extends AbstractModuleForm<HeroModuleContentInput, HeroModule> {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  public initialContent: HeroModuleContent = heroContentFactory()

  public activeTab: string | null = '0'

  public get hasSlides (): boolean {
    return Array.isArray(this.slides) && this.slides.length > 0
  }

  public get slides (): DashmixBoxTabItem[] {
    return [
      ...this._content.slides.map((slide, index) => {
        return {
          props: {
            slide,
            index
          },
          tab: {
            id: `${slide.id}`,
            disabled: false,
            label: SlideTabLabel,
            props: {
              tabIndex: index,
              label: `${slide.id}`,
              onSlideRemove: this.removeSlide
            }
          }
        }
      })
    ]
  }

  public set slides (slides: DashmixBoxTabItem[]) {
    this._content = {
      slides: slides.map((slide: DashmixBoxTabItem) => {
        if (!slide.props) {
          return {} as SlidesInput
        }

        return slide.props.slide as SlidesInput
      })
    }
  }

  public addNewSlide (): void {
    this._content.slides.push(this.createSlide())

    this.activeTab = this._content.slides[this._content.slides.length - 1].id
  }

  /**
   * Replaces current slide with updated item
   * @param slide - new slide info
   * @param index - list index of element to edit
   */
  public updateSlide (slide: SlidesInput, index: number): void {
    if (typeof this._content.slides === 'undefined' || !Array.isArray(this._content.slides)) {
      throw new Error(
        'HeroModuleForm.updateSlide(): Try to update slide in not existing slide list!')
    }

    const contentSlideCopy: SlidesInput[] = [...this._content.slides]

    contentSlideCopy[index] = slide
    this._content.slides = contentSlideCopy
  }

  protected createSlide (): SlidesInput {
    return {
      id: 'slide ' + Math.floor(Math.random() * 1000).toString(),
      addon: null,
      background: null,
      video: null,
      contentColor: '',
      link: null,
      additionalLink: null,
      imageAsLink: false,
      heading: {
        level: 1,
        color: '',
        content: '',
        size: 'large'
      },
      subHeading: ''
    }
  }

  private removeSlide (index: number): void {
    if (typeof this._content.slides === 'undefined' || !Array.isArray(this._content.slides)) {
      throw new Error(
        'HeroModuleForm.removeSlide(): Try to remove slide from not existing slide list!')
    }

    this._content.slides.splice(index, 1)

    if (this._content.slides.length > 0) {
      if (index !== 0) {
        this.activeTab = this.slides[index - 1].tab.id.toString()
      } else {
        this.activeTab = this.slides[index].tab.id.toString()
      }
    } else {
      this.activeTab = null
    }
  }

  @Watch('activeTab')
  protected onActiveTabChange (): void {
    this.eventBus.emit(REFRESH_MDE_KEY)
  }
}

export default HeroModuleForm
