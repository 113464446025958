













































































import { Component, Watch } from 'vue-property-decorator'

import { AbstractModuleForm } from '../../abstract/form'
import { NavigationForm } from '../../partials/NavigationForm'
import { FormFieldset } from '../../partials/FormFieldset'
import { ImageForm, UnresolvedImage } from '../../partials/ImageForm'
import { LinkForm } from '../../partials/LinkForm/LinkForm.vue'
import { MarkdownEditor } from '../../partials/MarkdownEditor'

import { NavbarModule, NavbarModuleContentInput } from '../Navbar.contracts'
import { navbarContentFactory } from '../Navbar.factory'
import { NavbarModuleVersion } from '../Navbar.config'

@Component<NavbarModuleForm>({
  name: 'NavbarModuleForm',
  components: { NavigationForm, FormFieldset, ImageForm, LinkForm, MarkdownEditor },
  mounted () {
    this.image = {
      ...this._content.brandImageDimensions,
      image: this._content.brandImage ?? null
    }
    this.secondaryImage = {
      ...this._content.secondaryBrandImageDimensions,
      image: this._content.secondaryBrandImage ?? null
    }
  }
})
export class NavbarModuleForm extends AbstractModuleForm<NavbarModuleContentInput, NavbarModule> {
  protected initialContent = navbarContentFactory()

  public image: UnresolvedImage | null = null
  public secondaryImage: UnresolvedImage | null = null

  public get defaultVersion (): boolean {
    return this._version === NavbarModuleVersion.Default
  }

  public get simpleVersion (): boolean {
    return this._version === NavbarModuleVersion.Simple
  }

  public get hasCart (): boolean {
    return this._content.hasCart !== false
  }

  public set hasCart (value: boolean) {
    this._content.hasCart = value
  }

  public setImage (image: UnresolvedImage | null, key: 'brandImage'|'secondaryBrandImage') {
    const dimensionsKey = `${key}Dimensions`
    if (!image) {
      const contentCopy = { ...this._content }
      delete contentCopy[key]
      delete contentCopy[dimensionsKey]
      this._content = contentCopy
      return
    }

    this._content = {
      ...this._content,
      [key]: image.image,
      [dimensionsKey]: { maxWidth: image.maxWidth, maxHeight: image.maxHeight }
    }
  }

  @Watch('image', { deep: true })
  public updateBrandImage (image: UnresolvedImage | null) {
    this.setImage(image, 'brandImage')
  }

  @Watch('secondaryImage', { deep: true })
  public updateSecondaryBrandImage (image: UnresolvedImage | null) {
    this.setImage(image, 'secondaryBrandImage')
  }
}

export default NavbarModuleForm
